import React, { useState, useReducer, useEffect, useMemo } from 'react';
import { Route, useLocation, Navigate, Routes, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { getPropsFromQuery, regexMultiTest, storageAvailable } from '../utils/functions';
import CourseLibrary from './course/CourseLibrary';
import LoginPage from './auth/LoginPage';
import RegisterPage from './auth/RegisterPage';
import Logout from './auth/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../actions';
import { getMe } from '../utils/requests';
import { RootState } from '../store';
import LoadingSpinner from './core/layout/LoadingSpinner/LoadingSpinner';
import ProfilePage from './auth/profile/ProfilePage';
import ResetPasswordPage from './auth/ResetPasswordPage';
import { closeModal, ModalContext, modalReducer } from '../contexts/ModalContext';
import ModalDisplay from './core/display/Modal/ModalDisplay';
import NotFoundPage from './error/NotFoundPage';
import ErrorAlert from './error/ErrorAlert';
import ErrorLogPage from './error/ErrorLogPage';
import { LOGIN_REDIRECT_EXCEPTION_REGEXES, LOGIN_RETURN_PATH_KEY } from '../utils/constants';
import EmailVerifyPrompt from './auth/EmailVerifyPrompt';
import VerifyPage from './auth/VerifyPage';
import Purchasing from './auth/purchasing/Purchasing';
import AdminController from './admin/AdminController';
import Rubrics from './rubric/Rubrics';
import CourseController from './course/CourseController';
import NotificationsPage from './auth/profile/NotificationsPage';
import NotificationActionHandler from './auth/profile/NotificationActionHandler';
import UserLibrary from './admin/UserLibrary';
import UserDetailsPage from './admin/UserDetailsPage';
import AssignmentLibrary from './assignment/AssignmentLibrary';
import ForgotPasswordPage from './auth/ForgotPasswordPage';
import { applyAccessibilitySettings } from './auth/profile/AccessibilityOptions';
import SiteBanner from './core/layout/Site/SiteBanner';
import SiteContainer from './core/layout/Site/SiteContainer';
import StorageDetector from './auth/StorageDetector';
import MfaVerificationPage from './auth/MfaVerificationPage';
import MfaEnrollmentPage from './auth/MfaEnrollmentPage';
import ContractMenu from './admin/AdminPurchasing/ContractMenu';
import ContractSeatTracking from './admin/AdminPurchasing/ContractSeatTracking';
import DomainSeatTracking from './admin/AdminPurchasing/DomainSeatTracking';

function App(): JSX.Element {
  const [userLoaded, setUserLoaded] = useState(false);

  const [modal, modalDispatch] = useReducer(modalReducer, null);
  const modalData = { modal, modalDispatch };

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user);
  const errors = useSelector((state: RootState) => state.errors);
  const queryProps = useMemo(() => getPropsFromQuery(new URLSearchParams(location.search)), [location]);

  useEffect(() => {
    applyAccessibilitySettings();
  }, []);

  useEffect(() => {
    modalDispatch(closeModal());
  }, [location, modalDispatch]);

  useEffect(() => {
    const hotkeyHandler = (e: KeyboardEvent) => {
      if (e.key === '`' && e.ctrlKey) navigate('/error-log');
    };
    window.addEventListener('keydown', hotkeyHandler);
    return () => window.removeEventListener('keydown', hotkeyHandler);
  }, [navigate]);

  useEffect(() => {
    if (user.userId === '' && !userLoaded)
      getMe(
        (user) => {
          dispatch(setUser(user));
          setUserLoaded(true);
          Sentry.setUser({ id: user.userId + '' });
          if (user.forcePasswordChange) navigate(`/reset_password`);
          if (user.requireMfaEnrollment && !user.actingAs) navigate(`/mfa/enroll`);
        },
        () => {
          const { pathname } = location;
          if (storageAvailable('sessionStorage')) window.sessionStorage.setItem(LOGIN_RETURN_PATH_KEY, pathname);
          if (!regexMultiTest(LOGIN_REDIRECT_EXCEPTION_REGEXES, pathname)) navigate(`/login`);
          setUserLoaded(true);
          return true;
        },
      );
  }, [user, dispatch, location, navigate, userLoaded]);

  const pageProps = { ...queryProps };

  if (userLoaded) {
    return (
      <ModalContext.Provider value={modalData}>
        <SiteBanner />
        <SiteContainer>
          <ModalDisplay modal={modal} />
          <ErrorAlert errors={errors} />
          <StorageDetector />
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login/">
              <Route index element={<LoginPage />} />
              <Route path="forgot_password" element={<ForgotPasswordPage {...pageProps} />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
            <Route path="/reset_password/:resetPasswordCode?" element={<ResetPasswordPage {...pageProps} />} />
            <Route path="/logout" element={<Logout {...pageProps} />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/verify-email" element={<EmailVerifyPrompt {...pageProps} />} />
            {['/user/:userId/verify/:verificationCode/*', '/user/:userId/activate/:activationCode/*'].map((path) => (
              <Route key={path} path={path} element={<VerifyPage />} />
            ))}
            <Route path="/purchase/*" element={<Purchasing path="/purchase/*" {...pageProps} />} />
            <Route path="/notifications/*" element={<NotificationsPage {...pageProps} />} />
            <Route path="/notification-action/*" element={<NotificationActionHandler {...pageProps} />} />
            <Route path="/profile/*" element={<ProfilePage {...pageProps} />} />
            <Route path="/course" element={<Navigate to="/course/dashboard" />} />
            {['/course/dashboard/*', '/course/templates/*'].map((path) => (
              <Route key={path} path={path} element={<CourseLibrary path={path} {...pageProps} />} />
            ))}
            <Route path="/course/:courseId/*" element={<CourseController {...pageProps} />} />
            <Route path="/assignments/*" element={<AssignmentLibrary {...pageProps} />} />
            <Route path="/rubrics/*" element={<Rubrics {...pageProps} />} />
            <Route path="/users/*" element={<UserLibrary {...pageProps} />} />
            <Route path="/user/:userId" element={<UserDetailsPage {...pageProps} />} />
            <Route path="/error-log" element={<ErrorLogPage errors={errors} />} />
            <Route path="/admin/*" element={<AdminController {...pageProps} />} />
            <Route path="/mfa/">
              <Route path="verify" element={<MfaVerificationPage {...pageProps} />} />
              <Route path="enroll" element={<MfaEnrollmentPage {...pageProps} />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
            <Route path="/contract" element={<ContractMenu />} />
            <Route path="/contract/:contractId/seats" element={<ContractSeatTracking />} />
            <Route path="/domain/:approvedDomainId/seats" element={<DomainSeatTracking />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </SiteContainer>
      </ModalContext.Provider>
    );
  }
  return <LoadingSpinner />;
}

export default Sentry.withProfiler(App);
